import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.PvpUrl}/product`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.PvpUrl}/product/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.PvpUrl}/product/`+id,formData);
    }

    create(formData){
		return this.http.post(`${environment.PvpUrl}/product`,formData);
	  }

    delete(id){
      return this.http.delete(`${environment.PvpUrl}/product/`+id);
    }

}
