import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

export class UserModel {

	id?: number;
	first_name: string;
	last_name: string;
	email: string;
	username: string;
	address_1: string;
	address_2: string;
	phone: string;
	date_of_birth: string;
	post_code: string;
	phone_prefix: string;
	remaining_activations: string;
	password_confirmation: string;
	password: string;
	image: any[];
	groups: any[];


	constructor(input?) {
		input = input || {};
		this.id = input.id || '';
		this.first_name = input.first_name || '';
		this.last_name = input.last_name || '';
		this.email = input.email || '';
		this.username = input.username || '';
		this.address_1 = input.address_1 || '';
		this.address_2 = input.address_2 || '';
		this.phone = input.phone || '';
		this.phone_prefix = input.phone_prefix || '';
		this.post_code = input.post_code || '';
		this.remaining_activations = input.remaining_activations || '';
		this.date_of_birth = input.date_of_birth || '';
		this.password = input.password || '';
		this.password_confirmation = input.password_confirmation || '';
		this.image = input.image || [];
		this.groups = input.groups || [];
	}

	// static getgroupsFormGroup(groups?) {
	// 	let fb: FormBuilder = new FormBuilder();

	// 	return fb.group({
	// 		id: new FormControl(groups ? groups.id : ''),
	// 	});
	// }

	static getFormGroup(input): FormGroup {
		let fb: FormBuilder = new FormBuilder();



		let groupsFormArray = [];
		if (input.groups) {
			for (let group of input.groups) {
				groupsFormArray.push(group.title);
			}
			console.log([groupsFormArray])
		}



		return fb.group({
			first_name: [input.first_name, Validators.required],
			last_name: [input.last_name, Validators.required],
			email: [input.email, [Validators.required, Validators.email]],
			address_1: [input.address_1],
			address_2: [input.address_2],
			date_of_birth: [input.date_of_birth],
			phone_prefix: [input.phone_prefix],
			phone: [input.phone],
			post_code: [input.post_code],
			remaining_activations: [input.remaining_activations],
			username: [input.username],
			password_confirmation: [input.password_confirmation],
			password: [input.password],
			image: [{}],
			groups: [input.groups ? input.groups.id: null]
		})
	}
}