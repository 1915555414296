import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.PvpUrl}/permission/group`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.PvpUrl}/permission/group/`+id);
    }

    // edit(id,formData){
    //   return this.http.put(`${environment.PvpUrl}/permission/group/`+id,formData);
    // }

    // create(formData){
	// 	return this.http.post(`${environment.PvpUrl}/permission/group`,formData);
	//   }

    // delete(id){
    //   return this.http.delete(`${environment.PvpUrl}/permission/group/`+id);
    // }

}
