import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private http : HttpClient
  ) { }

    sales(datefrom, dateto){
      return this.http.get(`${environment.PvpUrl}/report/sales?date_from=` + datefrom+ `&date_to=`+dateto);
    }

    activation(datefrom, dateto){
      return this.http.get(`${environment.PvpUrl}/report/activation?date_from=` + datefrom+ `&date_to=`+dateto);
    }

}
